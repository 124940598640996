import React, { useContext } from 'react';
import { DottedPatternProps } from './DottedPattern';
import { Section, SectionWrapper } from './Section';
import Title, { TitleProps } from './Title';
import styled from 'styled-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export interface TestimonialsProps {
    id?: string;
    title?: TitleProps;
    dottedPattern?: DottedPatternProps;
    items?: {
        title?: TitleProps;
        testimonial?: string;
        logo?: {
            imageUrl?: string;
            rounded?: boolean;
        };
    }[];
}

const TestimonialWrapper = styled.div`
    margin-top: 4rem;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
`;

const TestimonialItemWrapper = styled.div<{ mobile?: boolean }>`
    flex-grow: 1;
    flex-basis: ${props => (props.mobile ? '95%' : '45%')};
    padding: 1.5%;
`;

const TestimonialItem = styled.div`
    flex-direction: column;
    display: flex;
    height: 100%;
    border: 1px solid #dfe2e6;
    text-align: left;
    width: 100%;
    border-radius: 6px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    background-color: var(--bgSecondary);
    color: var(--textNormal);
    border: var(--bgSecondary);
`;

const TestimonialItemText = styled.div`
    padding: 1.5rem;
`;

const TestimonialContent = styled.div`
    margin-top: 1.5rem;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Testimonials = (props: TestimonialsProps) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <SectionWrapper dottedPattern={props.dottedPattern}>
            <Section id={props.id} title={props.title}>
                <TestimonialWrapper>
                    {props?.items?.map((item, index) => {
                        return (
                            <TestimonialItemWrapper key={`testimonial-${index}`} mobile={mobile}>
                                <TestimonialItem>
                                    <TestimonialItemText>
                                        <TitleWrapper>
                                            <Title {...item.title} />
                                            {item?.logo?.imageUrl && (
                                                <img
                                                    src={item?.logo?.imageUrl}
                                                    height={64}
                                                    style={{
                                                        maxWidth: 125628,
                                                        borderRadius: item.logo.rounded ? '5px' : '0px',
                                                    }}
                                                />
                                            )}
                                        </TitleWrapper>
                                        <TestimonialContent>{item.testimonial}</TestimonialContent>
                                    </TestimonialItemText>
                                </TestimonialItem>
                            </TestimonialItemWrapper>
                        );
                    })}
                </TestimonialWrapper>
            </Section>
        </SectionWrapper>
    );
};

export default Testimonials;
