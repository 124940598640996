import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from '../context/GlobalContextProvider';
import { DottedPatternProps } from './DottedPattern';
import { Section, SectionWrapper } from './Section';
import Title, { TitleProps } from './Title';

export interface ServiceItem {
    title?: TitleProps;
    points?: string[];
    coverImageUrl?: string;
    coverImageUrlDark?: string;
}

export interface ServiceProps {
    id?: string;
    title?: TitleProps;
    items?: ServiceItem[];
    dottedPattern?: DottedPatternProps;
}

const ServiceItemWrapper = styled.div<{ mobile: boolean }>`
    flex-basis: 0;
    flex-grow: 1;
    max-width: ${props => (props.mobile ? '100%' : '31%')};
    border-radius: 6px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    text-align: left;
    border-radius: 5px;
    border: 1px solid #dfe2e6;
    background-color: var(--bgSecondary);
    color: var(--textNormal);
    border: var(--bgSecondary);
    margin-bottom: 1.5rem;
`;

const ServiceImage = styled.img`
    width: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

const ServiceDescription = styled.div`
    padding: 1.5rem;
    ul {
        list-style-type: none;
        padding-inline-start: 0;
    }

    li {
        margin-top: 2rem;
    }
`;

const ServiceListWrapper = styled.div<{ mobile: boolean }>`
    margin-top: 4rem;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
`;

export const ServiceItem = (serviceItemProps: ServiceItem) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    const context = useContext(GlobalContext);

    // useEffect(() => {
    //     window.addEventListener(
    //         'storage',
    //         function (e: any) {
    //             console.log(e);
    //         },
    //         false,
    //     );
    // });

    return (
        <ServiceItemWrapper mobile={mobile}>
            {/* <ServiceImage src={serviceItemProps.coverImageUrl} alt="" /> */}
            <ServiceDescription>
                <img
                    src={context.theme === 'dark' ? serviceItemProps.coverImageUrlDark : serviceItemProps.coverImageUrl}
                    style={{ marginTop: '1.5rem' }}
                    height="100"
                />
                <Title {...serviceItemProps.title} />
                <ul>
                    {serviceItemProps.points?.map((point, index: number) => (
                        <li key={index}>
                            <FontAwesomeIcon icon={faArrowRight} /> {point}
                        </li>
                    ))}
                </ul>
            </ServiceDescription>
        </ServiceItemWrapper>
    );
};

export const ServiceList = ({ children }: { children?: any }) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return <ServiceListWrapper mobile={mobile}>{children}</ServiceListWrapper>;
};

export const Services = (serviceProps: ServiceProps) => {
    return (
        <SectionWrapper dottedPattern={serviceProps.dottedPattern}>
            <Section id={serviceProps.id} title={serviceProps.title}>
                <ServiceList>
                    {serviceProps.items?.map((serviceItem, serviceItemIndex) => (
                        <ServiceItem key={serviceItemIndex} {...serviceItem} />
                    ))}
                </ServiceList>
            </Section>
        </SectionWrapper>
    );
};

export default Services;
