import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { DottedPatternProps } from './DottedPattern';
import { Section, SectionWrapper } from './Section';
import { TitleProps } from './Title';

export interface ContactProps {
    id?: string;
    title?: TitleProps;
    dottedPattern?: DottedPatternProps;
}

const FormWrapper = styled.div`
    margin-top: 4rem;
    display: flex;
    width: 100%;
    justify-content: center;

    select,
    option,
    input,
    textarea {
        color: var(--textNormal);
    }
`;

const Form = styled.form<{ mobile?: boolean }>`
    display: flex;
    width: ${props => (props.mobile ? '100%' : '50%')};
    flex-direction: column;

    input,
    textarea,
    select,
    option {
        min-height: 2rem;
        line-height: 2rem;
        border: 0;
        border-bottom: 1px solid #100e17;
        border-radius: 5px;
        border-radius: 0px;
        flex-grow: 1;
        padding: 0 1rem;
        -ms-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        background-color: transparent;
    }
`;

const FormGroup = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
`;

export const Contact = ({ id, title, dottedPattern }: ContactProps) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    const formName = 'contact';
    return (
        <SectionWrapper dottedPattern={dottedPattern}>
            <Section id={id} title={title}>
                <FormWrapper>
                    <Form
                        mobile={mobile}
                        name="contact"
                        method="POST"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <FormGroup>
                            <input name="full-name" placeholder="Full Name" required />
                        </FormGroup>
                        <FormGroup>
                            <input name="email" placeholder="Email" type="email" required />
                        </FormGroup>
                        <FormGroup>
                            <select name="time-frame" required>
                                <option value="" disabled selected hidden>
                                    Choose a Timeframe
                                </option>
                                <option>2 - 4 Weeks</option>
                                <option>1 - 2 Months</option>
                                <option>2 - 4 Months</option>
                                <option>4 - 6 Months</option>
                                <option>6+ Months</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <select name="budget" required>
                                <option value="" disabled selected hidden>
                                    Choose a Budget
                                </option>
                                <option>Less than $2,000</option>
                                <option>$2,000 - $5,000</option>
                                <option>$5,000 - $8,000</option>
                                <option>$8,000 +</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <textarea
                                name="requirements"
                                rows={4}
                                placeholder="Please tell me more about your project and how I can help."
                                required
                            ></textarea>
                        </FormGroup>
                        <FormGroup>
                            <Button customStyling={{ flexGrow: 1 }} padding="large" type="submit">
                                Submit Project Details
                            </Button>
                        </FormGroup>
                    </Form>
                </FormWrapper>
            </Section>
        </SectionWrapper>
    );
};

export default Contact;
