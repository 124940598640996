import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect/dist/core';

const StyledGreetings = styled.div`
    font-size: 1rem;
    color: #0652dd;
    margin-left: 10px;
`;

const GreetingsWrapper = styled.div`
    display: flex;
`;

export const Greetings = () => {
    const messages = [
        '< Hello, World! />',
        '< Namaste, World! />',
        '< Bonjour, World! />',
        '< Hola, World! />',
        '< Ciao, World! />',
    ];
    const [messageIndex, setMessageIndex] = useState(0);

    useEffect(() => {
        new Typewriter('#greeting-messages', {
            strings: messages,
            autoStart: true,
            loop: true,
        });
    }, []);

    return (
        <GreetingsWrapper>
            👋
            <StyledGreetings id="greeting-messages">{messages[messageIndex]}</StyledGreetings>
        </GreetingsWrapper>
    );
};

export default Greetings;
