import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import config from '../config';
import Work from '../components/Work';
import Testimonials from '../components/Testimonials';
import Contact from '../components/Contact';

const IndexPage = () => (
    <>
        <SEO title="Freelance Full Stack Developer for Web & Mobile" />
        <Layout>
            <Hero id="hero" />
            <Work id="work" {...config.work} />
            <Services id="services" {...config.services} />
            <About id="about" {...config.about} />
            <Testimonials id="testimonials" {...config.testimonials} />
            <Contact id="contact" {...config.contact} />
        </Layout>
    </>
);

export default IndexPage;
