import React from 'react';
import Title, { TitleProps } from './Title';
import Sparkles from './Sparkles';
import { Section, SectionWrapper } from './Section';
import { DottedPatternProps } from './DottedPattern';
import styled from 'styled-components';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

interface Certification {
    badgeImageLink: string;
    link: string;
    title: string;
}

export interface AboutProps {
    id?: string;
    title?: TitleProps;
    description?: string[];
    signature?: string;
    profilePicture?: string;
    profilePictureOverlayText?: string;
    profilePictureOverlayShinyText?: string;
    certificates?: Certification[];
    dottedPattern?: DottedPatternProps;
}

const MainContent = styled.div<{ mobile?: boolean }>`
    margin-top: 3rem;
    display: flex;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
    width: 100%;
    align-items: center;
`;

const CertificationContent = styled.div<{ mobile?: boolean }>`
    margin-top: 80px;
    display: flex;
    justify-content: space-around;
    gap: 30px;
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};
`;

const Certificate = styled.div`
    padding: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    text-align: left;
    border-radius: 5px;
    border: 1px solid #dfe2e6;
    background-color: var(--bgSecondary);
    color: var(--textNormal);
    border: var(--bgSecondary);
`;

const CertificateBadge = styled.img`
    height: 120px;
    padding: 20px;
`;

const ProfilePictureWrapper = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
`;

const ProfilePictureContent = styled.div`
    position: relative;
    width: 250px;
    height: 250px;
    padding: 40px;

    img {
        border-radius: 5px !important;
    }
`;

const DescriptionWrapper = styled.div<{ mobile?: boolean }>`
    width: ${props => (props.mobile ? '100%' : '50%')};
    text-align: left;

    p {
        margin-bottom: 1.5rem;
    }
`;

const Signature = styled.div`
    font-family: 'Yellowtail', cursive;
    font-size: 1.6rem;
    margin-top: 30px;
`;

const ExperienceWrapper = styled.div`
    bottom: -10px;
    left: -10px;
    position: absolute;
    background-color: #0652dd;
    height: 100px;
    width: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
`;

const Experience = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;

    strong {
        font-size: 1.5rem;
    }
`;

const ProfilePicture = styled.img`
    width: 100%;
`;

const CertificateInfo = styled.div`
    text-align: left;
`;

export const About = (props: AboutProps) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <SectionWrapper dottedPattern={props.dottedPattern}>
            <Section id={props.id} title={props.title}>
                <MainContent mobile={mobile}>
                    <ProfilePictureWrapper>
                        <ProfilePictureContent>
                            <ProfilePicture src="me.jpg" />
                            <ExperienceWrapper>
                                <Experience>
                                    <small>{props.profilePictureOverlayText}</small>
                                    <Sparkles>
                                        <strong>{props.profilePictureOverlayShinyText}</strong>
                                    </Sparkles>
                                </Experience>
                            </ExperienceWrapper>
                        </ProfilePictureContent>
                    </ProfilePictureWrapper>
                    <DescriptionWrapper mobile={mobile}>
                        {props?.description?.map((para, index) => (
                            <p key={`para-${index}`}>{para}</p>
                        ))}
                        <Signature>— {props.signature}</Signature>
                    </DescriptionWrapper>
                </MainContent>
                <CertificationContent mobile={mobile}>
                    {props.certificates?.map((cert, index) => (
                        <Certificate key={`cert-${index}`}>
                            <CertificateBadge key={`cert-${index}`} src={cert.badgeImageLink} />
                            <CertificateInfo>
                                <Title
                                    value={cert.title}
                                    size="h4"
                                    accent={false}
                                    alignment="left"
                                    link={cert.link}
                                    linkText="CERTIFICATE"
                                    linkNextLine
                                />
                            </CertificateInfo>
                        </Certificate>
                    ))}
                </CertificationContent>
            </Section>
        </SectionWrapper>
    );
};

export default About;
