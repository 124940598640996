import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import { Link as LinkWithScroll } from 'react-scroll';
import styled, { keyframes } from 'styled-components';
import Greetings from './Greetings';
import { Section } from './Section';

const HeroWrapper = styled.div<{ mobile?: boolean }>`
    height: calc(100% - 10vh);
    display: flex;
    width: 100%;
    text-align: ${props => (props.mobile ? 'center' : 'left')};
    align-items: center;
    justify-content: ${props => (props.mobile ? 'center' : 'space-between')};
    flex-direction: ${props => (props.mobile ? 'column' : 'row')};

    h1 {
        color: var(--textNormal);
    }
`;

const TextColumn = styled.div<{ mobile?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${props => (props.mobile ? '100%' : '50%')};
    align-items: ${props => (props.mobile ? 'center' : 'left')};
`;

const ImageColumn = styled.div<{ mobile?: boolean }>`
    display: flex;
    width: ${props => (props.mobile ? '100%' : '50%')};
    margin-top: ${props => (props.mobile ? '5.5rem' : '0')};
    justify-content: ${props => (props.mobile ? 'center' : 'flex-end')};
`;

const GreetingsWrapper = styled.div`
    margin-bottom: 1.5rem !important;
`;

const TitleWrapper = styled.div<{ mobile?: boolean }>`
    margin-bottom: 1.5rem !important;
    line-height: 1;

    h1 {
        font-size: ${props => (props.mobile ? '2rem' : '3rem')};
        font-weight: normal;
        color: var(--textNormal);
        margin-bottom: 0;
        margin-top: 0;
    }
`;

const ShortAboutMe = styled.p`
    margin-bottom: 1.5rem !important;
`;

const VirtualMe = styled.img<{ mobile?: boolean }>`
    width: ${props => (props.mobile ? '200px' : '60%')};
    height: ${props => (props.mobile ? '200px' : undefined)};
    animation: float 6s ease-in-out infinite;

    @keyframes float {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-20px);
        }
        100% {
            transform: translatey(0px);
        }
    }
`;

const ScrollDownWrapper = styled.div`
    cursor: pointer;
    font-size: 15px;
    font-style: italic;
`;

const Highlighter = styled.div<{ mobile?: boolean }>`
    margin-top: ${props => (props.mobile ? '-15px' : '-20px')};
    width: 100%;
    background-color: rgba(72, 48, 230, 0.2);
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
`;

const BounceAnimation = keyframes`
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
`;

const Bounce = styled.span`
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: ${BounceAnimation} 3s infinite;
    display: inline-block;
`;

export const Hero = ({ id }: { id: string }) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;

    return (
        <Section hideBottomMargin={true} id={id} height={90}>
            <HeroWrapper mobile={mobile}>
                <TextColumn mobile={mobile}>
                    <GreetingsWrapper>
                        <Greetings />
                    </GreetingsWrapper>
                    <TitleWrapper mobile={mobile}>
                        <h1>Rishav Anand,</h1>
                        <h1>Tech Consultant </h1>
                        <Highlighter mobile={mobile}>&nbsp; </Highlighter>
                    </TitleWrapper>
                    <ShortAboutMe>
                        I empower companies in Crafting Digital Solutions
                        {/* I&apos;m Rishav 👋 and I craft robust apps and websites that help small businesses bring their
                        ideas to life. I have over 7 years of experience in full-stack software development. You can
                        check out my latest works below. */}
                    </ShortAboutMe>
                    <ScrollDownWrapper>
                        <LinkWithScroll to="work" smooth={true} offset={-70} duration={500}>
                            View Portfolio &nbsp;&nbsp;
                            <Bounce>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Bounce>
                        </LinkWithScroll>
                    </ScrollDownWrapper>
                </TextColumn>
                <ImageColumn mobile={mobile}>
                    <VirtualMe mobile={mobile} src={'/illustration.png'} />
                </ImageColumn>
            </HeroWrapper>
        </Section>
    );
};

export default Hero;
